import PropTypes from 'prop-types'
import styles from './general.module.scss'
import Button from '../button'
import Carousel from 'nuka-carousel'
import Chevron from '../svg/Chevron.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useState } from 'react'
import ApiContext from '../../utils/api-context'
import ReactMarkdown from 'react-markdown'
import { useModal } from '../../lib/context/modal-context'

const OverviewModal = ({ images, title, description, perNight }) => {
  const [closing, setClosing] = useState(false)
  const [focused, setFocus] = useState(null)
  const { apiUrl, cdnUrl } = useContext(ApiContext)
  const { setModal, modal } = useModal()

  // console.log(images)

  const closePopup = () => {
    setModal(null)
  }

  const date = null

  return (
    <div className={`${styles.popup} modal-dialog`}>
      <div
        className={`${styles.popupWrapper} ${styles.popupWrapperLarge} ${
          closing ? styles.closing : ''
        }`}
      >
        <div className={`${styles.popupInner}`}>
          <div className={`${styles.popupHeader}`}>
            <h2 className={`${styles.popupHeaderText}`}>{title}</h2>
            <span className={`${styles.popupClose}`} onClick={closePopup} />
          </div>
          <div className={`${styles.popupContent}`}>
            <div className={`${styles.overview}`}>
              {images && (
                <div className={`${styles.gallery}`}>
                  <Carousel
                    slidesToScroll={1}
                    slidesToShow={1}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <button
                        className={styles.chevronLeft}
                        onClick={previousSlide}
                      >
                        <Chevron />
                      </button>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <button
                        className={styles.chevronRight}
                        onClick={nextSlide}
                      >
                        <Chevron />
                      </button>
                    )}
                    enableKeyboardControls
                    cellSpacing={2}
                    defaultControlsConfig={{
                      pagingDotsStyle: {
                        display: 'none',
                      },
                    }}
                  >
                    {images.map((image, i) => {
                      return (
                        <div className={styles.image} key={i}>
                          {image.image && image.image.url && (
                            <img src={`${image.image.url}`} alt="" />
                          )}
                          {image.url &&
                            image.url !== '' &&
                            image.url.indexOf('http') > -1 && (
                              <img src={image.url} alt="" />
                            )}
                          {image.url &&
                            image.url !== '' &&
                            image.url.indexOf('http') === -1 && (
                              <img src={`${image.url}`} alt="" />
                            )}
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              )}
              <div className={`${styles.overviewContent}`}>
                <h2>Overview</h2>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
OverviewModal.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  perNight: PropTypes.number,
}
export default OverviewModal
