import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../lib/context/UserContext'
import HeartActiveSvg from '../svg/HeartActive.svg'
import HeartSvg from '../svg/Heart.svg'
import styles from './general.module.scss'
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from '../../lib/queries'
import { useMutation } from '@apollo/client'

interface FavouriteProps {
  type: string
  id: number
}
const Favourite = ({ type, id }: FavouriteProps) => {
  const { user, setCurrentUser } = useContext(UserContext)
  const [favourite, setFavourite] = useState(false)
  const [createFavourite] = useMutation(ADD_TO_WISHLIST)
  const [deleteFavourite] = useMutation(REMOVE_FROM_WISHLIST)

  const isFavourite = () => {
    if (user && user.favourites) {
      return user.favourites.filter((item) => {
        if (
          type === 'hotel' &&
          item.hotel &&
          parseInt(item.hotel.id) === parseInt(id.toString())
        ) {
          return item
        } else if (
          type === 'therapist' &&
          item.therapist &&
          parseInt(item.therapist.id) === parseInt(id.toString())
        ) {
          return item
        } else if (
          type === 'experience' &&
          item.experience &&
          parseInt(item.experience.id) === parseInt(id.toString())
        ) {
          return item
        } else if (
          type === 'package' &&
          item.package &&
          parseInt(item.package.id) === parseInt(id.toString())
        ) {
          return item
        }
      })
    }
    return []
  }
  useEffect(() => {
    if (!favourite) {
      const hasFavourited = isFavourite()
      if (hasFavourited.length === 1) {
        setFavourite(true)
      }
    }
  })

  const addToWishlist = () => {
    if (favourite) {
      const hasFavourited = isFavourite()
      if (hasFavourited.length === 1) {
        deleteFavourite({
          variables: {
            id: hasFavourited[0].id,
          },
        }).then((resp) => {
          setCurrentUser({
            ...user,
            favourites: user.favourites.filter(
              (item) => item.id !== hasFavourited[0].id
            ),
          })
          setFavourite(false)
        })
      }
    } else {
      const variables = {
        user: user.id,
        hotelId: null,
        experienceId: null,
        therapistId: null,
        packageId: null,
      }
      variables[`${type}Id`] = id
      createFavourite({
        variables,
      }).then((resp) => {
        setCurrentUser({
          ...user,
          favourites: [...user.favourites, resp.data.createFavourite.favourite],
        })
        setFavourite(true)
      })
    }
  }
  return (
    <strong
      className={`${styles.favouriteSpan} favourite-${id}`}
      onClick={addToWishlist}
    >
      {favourite && <HeartActiveSvg />}
      {!favourite && <HeartSvg />}
    </strong>
  )
}
export default Favourite
